import React, {useState} from 'react'

import PropTypes from 'prop-types'

import './form-input.css'

const FormInput = (props) => {

  const [showError, setShowError] = useState(false);
  const errorStyles = {
    display: showError ? 'flex' : 'none',
  };

  const validateField = (e) => {
    if(!props.regex.test(e.target.value)){
      setShowError(true)
    }else{
      setShowError(false)
    }
  }

  const onKeyDownFunction = (e) => {
    if(e.key === "Tab" || e.key === "Control" || e.key === "Backspace" || e.key === "Delete" || props.regexInput.test(e.key) ){
    }else{
      e.preventDefault()
    }
  }

  return (
    <div className={`form-input-container ${props.rootClassName} `}>
      <div className="form-input-container1">
        <input
            onChange={(e)=>validateField(e)}
            name={props.name}
            onKeyDown={(e)=>onKeyDownFunction(e)}
            type="text"
            required={props.required}
            placeholder={props.required ? props.placeholder+"*" : props.placeholder}
          className="form-input-textinput input"
        />
        <span style={errorStyles} className="form-input-text" id={props.id}>{props.error}</span>

      </div>
    </div>
  )
}

FormInput.defaultProps = {
  name: "name",
  id:"id",
  placeholder: 'placeholder',
  rootClassName: '',
  error:'error',
  required:'false',
  regex:"regex",
  regexInput:"regex"
}

FormInput.propTypes = {
  name : PropTypes.string,
  id:PropTypes.string,
  placeholder: PropTypes.string,
  rootClassName: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  regex: PropTypes.any,
  regexInput: PropTypes.any
}

export default FormInput
