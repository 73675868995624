import React from 'react'

import { Helmet } from 'react-helmet'

import './don.css'

const Don = (props) => {

  return (
    <div className="don-container">
      <Helmet>
        <title>Don - FFRE</title>
        <meta property="og:title" content="Don - FFRE" />
      </Helmet>
      <img
        src="/logo%2030%20ans%20ffre_2021%20ffre%20v2-200h.png"
        alt="image"
        className="don-image"
      />
      <span className="don-text">
        Votre inscription a bien été enregistrée !
      </span>
      <span className="don-text01">
        <span>
          Merci, par l’intérêt que vous portez aux actions de la FFRE,
        </span>
        <br></br>
        <span>
          {' '}
          de contribuer à faire avancer la recherche sur l’épilepsie.
        </span>
      </span>
      <div className="don-container1">
        <img alt="image" src="/don.svg" className="don-image1" />
        <span className="don-text05">
          <span className="don-text06">
            Pour nous soutenir par un don déductible
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br className="don-text07"></br>
          <span className="don-text08">à 66% de votre impôt sur le revenu</span>
          <br className="don-text09"></br>
          <span className="don-text10">
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <span className="don-text11">
            (dans la limite de 20% de votre revenu net imposable)
            <span
              dangerouslySetInnerHTML={{
                __html: ' ',
              }}
            />
          </span>
          <br className="don-text12"></br>
          <span className="don-text13">cliquez ici :</span>
        </span>
        <a
          href="https://jedonne-fondation-epilepsie.iraiser.eu/colloque24"
          target="_blank"
          rel="noreferrer noopener"
          className="don-link"
        >
          Je fais un don à la FFRE
        </a>
      </div>
    </div>
  )
}

export default Don
