import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import './style.css'
import Don from './views/don'
import Home from './views/home'

const App = () => {
  return (
    <Router>
        <Routes>
            <Route element={<Don/>} path="/don" />
            <Route element={<Home/>} path="/" />
        </Routes>

    </Router>
  )
}

import { createRoot } from 'react-dom/client';
const container = document.getElementById('app');
const root = createRoot(container);

root.render(<App/>);
