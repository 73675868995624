import React, {useEffect, useState} from 'react'

import PropTypes from 'prop-types'

import './modify-invitation.css'

const ModifyInvitation = (props) => {

    const showModify = {
        height: "auto",
        display: "flex",
        transition: "1s",
    };
    const hideModify = {
        height: "0",
        display: "none",
        transition: "1s",
    };

    // Set the initial local state for the input values
    const [formValues, setFormValues] = useState({});

    const [showModifyForm, setShowModifyForm] = useState(false);

    useEffect(() => {
        // Update formValues whenever personne prop changes
        setFormValues({
            civilite: props.personne.civilite,
            nom: props.personne.nom,
            prenom: props.personne.prenom,
            email: props.personne.email,
            tel: props.personne.tel,
            adresse: props.personne.adresse,
            cp: props.personne.cp,
            ville: props.personne.ville,
        });
    }, [props.personne]);

    const modifyPersonne = (e, index) => {
        e.preventDefault();
        let listePersonne = [...props.listePersonne];
        listePersonne[index] = {...formValues}; // Update the list with the modified person
        props.setListePersonne(listePersonne);
        setShowModifyForm(false); // Hide the modify form after submission
    };

    const handleInputChange = (e) => {
        // Update the local state with the input changes
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value,
        });
    };

    return (
        <div key={props.index} style={{width: "100%", paddingBottom: "25px"}}>
            <div className={`modify-invitation-container ${props.rootClassName} `} style={showModifyForm ? {backgroundColor:"#E8F0FE"} : null}>
                <div className="modify-invitation-container1">
                    <span className="modify-invitation-text">{props.personne.nom} {props.personne.prenom}</span>
                </div>
                <div className="modify-invitation-container2">
                    <div className="modify-invitation-container3" style={{cursor: 'pointer'}}
                         onClick={() => {
                             setShowModifyForm(false)
                             props.removePersonne(props.index)
                         }}>
                        <img
                            alt="delete people"
                            src="/delete-200w.png"
                            className="modify-invitation-image"
                        />
                    </div>
                    {showModifyForm ? null : <div className="modify-invitation-container4" style={{cursor: 'pointer'}}
                                                  onClick={() => setShowModifyForm((prev) => true)}>
                        <img
                            alt="Update people"
                            src="/update-200h.png"
                            className="modify-invitation-image1"
                        />
                    </div>}

                </div>
            </div>
            <div style={showModifyForm ? showModify : hideModify}>
                <form className={"formModif"} onSubmit={(e) => modifyPersonne(e, props.index)}>

                    <div>
                        <input
                            type="text"
                            name="nom"
                            value={formValues.nom}
                            onChange={handleInputChange}
                            placeholder={"Nom"}
                        />
                        <img
                            alt="Update people"
                            src="/update-200h.png"
                            className="modify-invitation-image1"
                        />
                    </div>

                    <div>
                        <input
                            type="text"
                            name="prenom"
                            value={formValues.prenom}
                            onChange={handleInputChange}
                            placeholder={"Prénom"}
                        />
                        <img
                            alt="Update people"
                            src="/update-200h.png"
                            className="modify-invitation-image1"
                        />
                    </div>


                    <div>
                        <input
                            type="text"
                            name="email"
                            value={formValues.email}
                            onChange={handleInputChange}
                            placeholder={"Email"}
                        />
                        <img
                            alt="Update people"
                            src="/update-200h.png"
                            className="modify-invitation-image1"
                        />
                    </div>


                    <div>
                        <input
                            type="text"
                            name="tel"
                            value={formValues.tel}
                            onChange={handleInputChange}
                            placeholder={"Téléphone"}
                        />
                        <img
                            alt="Update people"
                            src="/update-200h.png"
                            className="modify-invitation-image1"
                        />
                    </div>


                    <div>
                        <input
                            type="text"
                            name="adresse"
                            value={formValues.adresse}
                            onChange={handleInputChange}
                            placeholder={"Adresse"}
                        /><img
                        alt="Update people"
                        src="/update-200h.png"
                        className="modify-invitation-image1"
                    />

                    </div>


                    <div>
                        <input
                            type="text"
                            name="cp"
                            value={formValues.cp}
                            onChange={handleInputChange}
                            placeholder={"Code postal"}
                        />
                        <img
                            alt="Update people"
                            src="/update-200h.png"
                            className="modify-invitation-image1"
                        />
                    </div>


                    <div>
                        <input
                            type="text"
                            name="ville"
                            value={formValues.ville}
                            onChange={handleInputChange}
                            placeholder={"Ville"}
                        />
                        <img
                            alt="Update people"
                            src="/update-200h.png"
                            className="modify-invitation-image1"
                        />
                    </div>

                    <div className="actionUpdate">
                        <p onClick={() => setShowModifyForm((prev) => false)} style={{cursor: 'pointer'}}>Annuler</p>
                        <input type="submit" className={"validateModif"} value={"Modifier"}
                               style={{cursor: 'pointer'}}/>
                    </div>

                </form>
            </div>
        </div>

    )
}

ModifyInvitation.defaultProps = {
    nom: 'nom',
    rootClassName: '',
}

ModifyInvitation.propTypes = {
    nom: PropTypes.string,
    rootClassName: PropTypes.string,
}

export default ModifyInvitation
