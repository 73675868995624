import React, {useState} from 'react'

import {Helmet} from 'react-helmet'

import ModifyInvitation from '../components/modify-invitation'
import FormGroup from '../components/form-group'
import './home.css'
import axios from "axios";
import Don from "./don";
import {useNavigate} from "react-router-dom";

const Home = (props) => {

    const errorCivilite = {
        width:"100%",
        display:"none"
    }

    const navigate = useNavigate()

    const [listePersonne, setListePersonne] = useState([]);

    const [disableBtn, setDisableBtn] = useState(false);

    const [redirect, setRedirect] = useState(false);

    if (redirect) {
        navigate("/don")
    }

    const regexNomPrenom = new RegExp("^[a-zA-ZéèÉÈÇÊçêïë ]{2,50}(-[a-zA-ZéèÉÈÇÊçêïë ]{2,50})?$");
    const regexEmail = new RegExp("^[a-zA-Z0-9._éèÉÈÇÊçêïë-]+@[a-zA-Z._-]+\\.[a-zA-Z]{2,100}$")
    const regexTel = new RegExp("^[0-9]{0,20}$")
    const regexCp = new RegExp("^[0-9]{0,10}$")
    const regexAdresse = new RegExp("^[a-zA-Z0-9 éèÉÈÇÊçêïë._-]{0,50}$")
    const regexVille = new RegExp("^[a-zA-Z éèÉÈÇÊçêïë._-]{0,50}$")

    const addPersonne = (e) => {
        hideErrors(["nomError","prenomError","civError","emailError"])
        e.preventDefault();

        const civility = e.target.civility.value;
        const nom = e.target.nom ? e.target.nom.value.trim() : "";
        const prenom = e.target.prenom ? e.target.prenom.value.trim() : "";
        const email = e.target.email ? e.target.email.value.trim() : "";
        const tel = e.target.tel ? e.target.tel.value.trim() : "";
        const adresse = e.target.adresse ? e.target.adresse.value.trim() : "";
        const cp = e.target.cp ? e.target.cp.value.trim() : "";
        const ville = e.target.ville ? e.target.ville.value.trim() : "";

        if (
            nom !== "" &&
            prenom !== "" &&
            email !== "" &&
            civility !== "" &&
            regexNomPrenom.test(nom) &&
            regexNomPrenom.test(prenom) &&
            regexEmail.test(email) &&
            regexTel.test(tel) &&
            regexAdresse.test(adresse) &&
            regexCp.test(cp) &&
            regexVille.test(ville)
        ) {
            let newListe = [...listePersonne, {
                civilite: e.target.civility.value,
                nom: e.target.nom.value,
                prenom: e.target.prenom.value,
                email: e.target.email.value,
                tel: e.target.tel !== undefined ? e.target.tel.value : "",
                adresse: e.target.adresse !== undefined ? e.target.adresse.value : "",
                cp: e.target.cp.value !== undefined ? e.target.cp.value : "",
                ville: e.target.ville.value !== undefined ? e.target.ville.value : "",
            }];
            setListePersonne(newListe);

            document.getElementsByName("nom")[document.getElementsByName("nom").length - 1].value = ""
            document.getElementsByName("prenom")[document.getElementsByName("nom").length - 1].value = ""
            document.getElementsByName("email")[document.getElementsByName("nom").length - 1].value = ""
            document.getElementsByName("tel")[document.getElementsByName("nom").length - 1].value = ""
            document.getElementsByName("adresse")[document.getElementsByName("nom").length - 1].value = ""
            document.getElementsByName("cp")[document.getElementsByName("nom").length - 1].value = ""
            document.getElementsByName("ville")[document.getElementsByName("nom").length - 1].value = ""

        }
    };

    const showErrors = (listError) =>{
        listError.map(error=>{
            error!==null ? document.getElementById(error).style.display="flex" :null
        })
    }

    const hideErrors= (listError)=>{
        listError.map(error=>{
            error!==null ? document.getElementById(error).style.display="none" :null
        })
    }

    const removePersonne = (index) => {
        hideErrors(["nomError","prenomError","civError","emailError"])
        let newListe = [...listePersonne];
        newListe.splice(index, 1);
        setListePersonne(newListe);
    }

    const handleForm = (e) => {

        hideErrors(["nomError","prenomError","civError","emailError"])
        e.preventDefault();

        const madame = document.getElementById("madame").checked;
        const monsieur = document.getElementById("monsieur").checked;

        let civility = ""

        if (madame === true) {
            civility = "Madame"
        }
        if (monsieur === true) {
            civility = "Monsieur"
        }

        const nom = document.getElementsByName("nom") ? document.getElementsByName("nom")[document.getElementsByName("nom").length - 1].value.trim() : "";
        const prenom = document.getElementsByName("prenom") ? document.getElementsByName("prenom")[document.getElementsByName("prenom").length - 1].value.trim() : "";
        const email = document.getElementsByName("email") ? document.getElementsByName("email")[document.getElementsByName("email").length - 1].value.trim() : "";
        const tel = document.getElementsByName("tel") ? document.getElementsByName("tel")[document.getElementsByName("tel").length - 1].value.trim() : "";
        const adresse = document.getElementsByName("adresse") ? document.getElementsByName("adresse")[document.getElementsByName("adresse").length - 1].value.trim() : "";
        const cp = document.getElementsByName("cp") ? document.getElementsByName("cp")[document.getElementsByName("cp").length - 1].value.trim() : "";
        const ville = document.getElementsByName("ville") ? document.getElementsByName("ville")[document.getElementsByName("ville").length - 1].value.trim() : "";


        if (nom !== "" && prenom !== "" && email !== "" && civility !== "" && regexNomPrenom.test(nom) &&
            regexNomPrenom.test(prenom) &&
            regexEmail.test(email) &&
            regexTel.test(tel) &&
            regexAdresse.test(adresse) &&
            regexCp.test(cp) &&
            regexVille.test(ville)) {

            let listToSend = [...listePersonne, {
                civilite: civility,
                nom: nom,
                prenom: prenom,
                email: email,
                tel: tel,
                adresse: adresse,
                cp: cp,
                ville: ville,
            }];

            setDisableBtn(true);

            axios.post(`${process.env.REACT_APP_API_URL}add_colloque`, listToSend).then(
                result => navigate("/don")
            ).catch(
                err => console.log(err.response.data)
            )
        }else{
            if (listePersonne.length<1){
                let listeError = []

                if (nom === "" || !regexNomPrenom.test(nom)){
                    listeError.push("nomError")
                }
                if (civility === ""){
                    listeError.push("civError")
                }
                if (prenom === "" || !regexNomPrenom.test(prenom)){
                    listeError.push("prenomError")
                }
                if (email === "" || !regexEmail.test(email)){
                    listeError.push("emailError")
                }

                showErrors(listeError)
            }

        }

        if (civility !== "" && nom === "" && prenom === "" && email === "" && cp === "" && ville === "" && tel === "" && adresse === "" && listePersonne.length>0) {
            setDisableBtn(true);
            axios.post(`${process.env.REACT_APP_API_URL}add_colloque`, listePersonne).then(
                result => navigate("/don")
            ).catch(
                err => console.log(err.response.data)
            )
        }else{
            if (listePersonne.length>0){
                let listeError = []

                if (nom === "" || !regexNomPrenom.test(nom)){
                    listeError.push("nomError")
                }
                if (civility === ""){
                    listeError.push("civError")
                }
                if (prenom === "" || !regexNomPrenom.test(prenom)){
                    listeError.push("prenomError")
                }
                if (email === "" || !regexEmail.test(email)){
                    listeError.push("emailError")
                }

                showErrors(listeError)
            }
        }

    }

    let currentDate=new Date();

    // Date sous la forme "02/10/2023 23:59"
    const dateString = "03/06/2024 23:59";

    // Divisez la chaîne de caractères en composantes de date et d'heure
    const dateComponents = dateString.split(" ");
    const datePart = dateComponents[0]; // "02/10/2023"
    const timePart = dateComponents[1]; // "23:59"

    // Divisez les composantes de date en jour, mois et année
    const dateParts = datePart.split("/");
    const day = parseInt(dateParts[0], 10); // Jour (02)
    const month = parseInt(dateParts[1], 10) - 1; // Mois (0-11, donc soustrayez 1)
    const year = parseInt(dateParts[2], 10); // Année (2023)

    // Divisez les composantes de l'heure en heures et minutes
    const timeParts = timePart.split(":");
    const hours = parseInt(timeParts[0], 10); // Heures (23)
    const minutes = parseInt(timeParts[1], 10); // Minutes (59)

        // Créez un objet Date avec les composantes de date et d'heure
    const dateObject = new Date(year, month, day, hours, minutes);

    let afficheForm = currentDate<dateObject;

    return (
        <div className="home-container">
            <Helmet>
                <title>Formulaire Colloque FFRE</title>
                <meta property="og:title" content="Formulaire Colloque FFRE"/>
            </Helmet>
            <img alt="Header FFRE" src="/header.png" className="home-image"/>
            <img alt="Header Mobile FFRE" src="/header-mobile-1500w.png" className="home-image1"/>
            <div className="home-container01">
                <div className="home-container02">

                    {afficheForm ?

                        <div className="home-container03">
                        <span className="home-text">
                          Inscrivez-vous via ce formulaire :
                        </span>
                            {listePersonne.length === 0 ?
                                null : <div className="home-container04">
                                    {listePersonne.map((personne, index) =>
                                        <ModifyInvitation key={index} personne={personne} removePersonne={removePersonne}
                                                          index={index}
                                                          setListePersonne={setListePersonne}
                                                          listePersonne={listePersonne}/>)}
                                    <div className="home-container05"></div>
                                </div>}
                            <form className="home-form" onSubmit={addPersonne}>
                                <div className="home-container06">
                                    <div className={"civAndError"}>
                                        <div className={"civilityContainer"}>
                                            <label htmlFor="civility">Civilité : </label>
                                            <div className={"civilityChoice"}>
                                                <p>Monsieur </p>
                                                <input type="radio" id={"monsieur"} value={"Monsieur"}
                                                       name={"civility"}/>
                                            </div>
                                            <div className={"civilityChoice"}>
                                                <p>Madame </p>
                                                <input type="radio" id={"madame"} value={"Madame"} name={"civility"}/>
                                            </div>
                                        </div>
                                        <p id={"civError"} style={errorCivilite}>Veuillez choisir une civilité</p>
                                    </div>

                                    <div className="home-container07">
                                        <FormGroup rootClassName="form-group-root-class-name"></FormGroup>
                                    </div>
                                    <div className="home-container08">
                                        <button type="button" className="home-button button">
                                        <span className="home-text1">
                                          <input type={"submit"} value={"+ Ajouter une personne"}
                                                 style={{
                                                     textDecoration: "underline",
                                                     background: "none",
                                                     color: "#0059A5",
                                                     cursor: "pointer"
                                                 }}/>
                                          <br></br>
                                        </span>
                                        </button>
                                        <input
                                            disabled={disableBtn}
                                            type="submit"
                                            value="Valider l'inscription"
                                            placeholder="placeholder"
                                            className="home-textinput input"
                                            onClick={handleForm}
                                            style={{cursor: "pointer"}}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>
                     : <p id={"tooLate"}>Il est désormais trop tard pour vous inscrire via ce formulaire. <br/>Pour vous inscrire, merci d'envoyer un email à <strong>mariemussato@fondation-epilepsie.fr</strong>
                        </p>
                    }

                </div>
                <div className="home-container09">
                    <img alt="image" src="/conference-300w.png" className="home-image2"/>
                </div>
            </div>
        </div>
    )
}

export default Home
