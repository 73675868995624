import React from 'react'

import PropTypes from 'prop-types'

import FormInput from './form-input'
import './form-group.css'

const FormGroup = (props) => {

    const regexNomPrenom = new RegExp("^[a-zA-ZéèÉÈÇÊçêïë ]{2,50}(-[a-zA-ZéèÉÈÇÊçêïë ]{2,50})?$");
    const regexEmail = new RegExp("^[a-zA-Z0-9._éèÉÈÇÊçêïë-]+@[a-zA-Z._-]+\\.[a-zA-Z]{2,100}$")
    const regexTel = new RegExp("^[0-9]*$")
    const regexCp = new RegExp("^[0-9]*$")
    const regexAdresse = new RegExp("^[a-zA-Z0-9 éèÉÈÇÊçêïë._-]{0,50}$")
    const regexVille = new RegExp("^[a-zA-Z éèÉÈÇÊçêïë._-]{0,50}$")

    return (
        <div className={`form-group-container ${props.rootClassName} `}>
            <FormInput
                name={"nom"}
                id={"nomError"}
                error="Veuillez entrer un nom valide"
                placeholder="Nom"
                required={true}
                rootClassName="form-input-root-class-name"
                className=""
                regex={regexNomPrenom}
                regexInput={new RegExp("^[a-zA-ZéèÉÈÇÊçêïë -]$")}
            ></FormInput>
            <FormInput
                name={"prenom"}
                id={"prenomError"}
                error="Veuillez entrer un prénom valide"
                placeholder="Prenom"
                required={true}
                rootClassName="form-input-root-class-name1"
                className=""
                regex={regexNomPrenom}
                regexInput={new RegExp("^[a-zA-ZéèÉÈÇÊçêïë -]$")}
            ></FormInput>
            <FormInput
                name={"email"}
                id={"emailError"}
                error="Veuillez entrer un email valide"
                placeholder="Email"
                required={true}
                rootClassName="form-input-root-class-name2"
                className=""
                regex={regexEmail}
                regexInput={new RegExp("^[a-zA-Z0-9._éèÉÈÇÊçêïë@-]$")}
            ></FormInput>
            <FormInput
                name={"tel"}
                id={"telError"}
                error="Veuillez entrer un numéro de téléphone valide"
                placeholder="Téléphone"
                required={false}
                rootClassName="form-input-root-class-name3"
                className=""
                regex={regexTel}
                regexInput={new RegExp("^[0-9]$")}
            ></FormInput>
            <FormInput
                name={"adresse"}
                id={"adresseError"}
                error="Veuillez entrer une adresse valide"
                placeholder="Adresse"
                required={false}
                rootClassName="form-input-root-class-name4"
                className=""
                regex={regexAdresse}
                regexInput={new RegExp("^[a-zA-Z0-9 éèÉÈÇÊçêïë._-]$")}
            ></FormInput>
            <div className="form-group-container1">
                <FormInput
                    name={"cp"}
                    id={"cpError"}
                    error="Veuillez entrer un code postal valide"
                    placeholder="Code postal"
                    required={false}
                    rootClassName="form-input-root-class-name5"
                    className=""
                    regex={regexCp}
                    regexInput={new RegExp("^[0-9]$")}
                ></FormInput>
                <FormInput
                    name={"ville"}
                    id={"villeError"}
                    error="Veuillez entrer une ville valide"
                    placeholder="Ville"
                    required={false}
                    rootClassName="form-input-root-class-name6"
                    className="" regex={regexVille}
                    regexInput={new RegExp("^[a-zA-ZéèÉÈÇÊçêïë -]$")}
                ></FormInput>
            </div>
        </div>
    )
}

FormGroup.defaultProps = {
    rootClassName: '',
}

FormGroup.propTypes = {
    rootClassName: PropTypes.string,
}

export default FormGroup
